
import { Component, Vue } from 'vue-property-decorator'
import SellerHeader from '@/components/Seller/SellerHeader.vue'

@Component({
  components: {
    SellerHeader,
  },
})
export default class ShareUser extends Vue {
  public fullname = this.$route.params.fullname
  public email = this.$route.params.email
  public password = this.$route.params.password
  public title = this.$route.params.title
  public username = this.$route.params.username
  public to:string = '/seller/administration/seller-list'
  public initials = this.$route.params.fullname.charAt(0)

  copyToClipBoard() {
    let seller_data = ''

    const appUrl = process.env.VUE_APP_APP_URL
      ? process.env.VUE_APP_APP_URL
      : 'https://app.tqr.la'

    seller_data +=
      'Hola ' +
      this.fullname +
      ', te dejamos tus credenciales de acceso a TQR. \n\n' +
      'Link de acceso: ' +
      appUrl +
      '\n\nUsuario: ' +
      this.username +
      '\n\nContraseña: ' +
      this.password

    navigator.clipboard.writeText(seller_data).then(
      function () {
        Vue.$toast.success(`Mensaje copiado!`)
      },
      function (err) {
        Vue.$toast.error(`Error copiando mensaje! Intenta nuevamente`)
      }
    )
  }

  shareWhatsapp() {
    //const whatsappLink = `https://wa.me/?text=${encodeURIComponent(productURL + '\n' + message)}`;

    var url = 'https://wa.me/?text='
    var seller_data = ''
    const appUrl = process.env.VUE_APP_APP_URL
      ? process.env.VUE_APP_APP_URL
      : 'https://app.tqr.la'

    seller_data +=
      'Hola ' +
      this.fullname +
      ', te dejamos tus credenciales de acceso a TQR. \n\n' +
      '\nLink de acceso: ' +
      appUrl +
      '\n\nUsuario: ' +
      this.username +
      '\n\nContraseña: ' +
      this.password

    url += encodeURIComponent(seller_data)
    window.open(url, '_blank')
  }

  mounted() {

    if(this.$route.params.title == 'Compartir Recepcionista'){
      this.to = this.$route.params.to;
    }

  }
}
